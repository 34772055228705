import React, { useState } from "react";
import { useAddress } from "../hooks/useAddress";
import * as styles from "../App.module.scss";
import { contractAddressByNetwork } from "../helpers/contractAddresses";

// const cutFacetAddressByNetwork = {
//   laddnet: "0x31AAdDd5df920970e9Ec6bD5ac91bFf9CD1C0479",
// };

export default function CreateDiamond(props) {
  const { signer, diamondFactory, writeContracts, networkToShow } = props;
  const [created, setCreated] = useState(false);
  const signerAddress = useAddress(signer);

  const createDiamond = (event) => {
    event.preventDefault();

    const cutFacetAddress =
      contractAddressByNetwork[networkToShow]["DiamondCutFacet"];
    diamondFactory.deploy(signerAddress, cutFacetAddress).then(() => {
      setCreated(true);
    });
  };

  const successMessage = created ? (
    <div className={styles.successMessage}>
      Your transaction to cut your diamond is pending. Your wallet should alert
      you transaction success in a couple minutes.
    </div>
  ) : null;

  return (
    <div className={styles.createDiamondSection}>
      <button
        className={styles.createDiamond}
        disabled={!signerAddress}
        onClick={createDiamond}
      >
        Deploy Diamond
      </button>
      {successMessage}
    </div>
  );
}
