const { cloneDeep, has, partial, toUpper, findLastIndex } = require("lodash");

const numeric = "1234567890";

const alpha = "abcdefghijklmnopqrstuvwxyz";
const hexAlpha = "abcdefgh";
const hexAlphaUpper = hexAlpha.toUpperCase();
const hexalphanumeric = numeric + hexAlpha + hexAlphaUpper;

function assert(value) {
  if (value !== true) {
    throw new Error("Value wasn't true:", value);
  }
}

class Index extends Object {
  constructor(start = 0) {
    super();
    this.i = start;
  }

  increment(num = 1) {
    this.i += num;
  }

  val() {
    return this.i;
  }

  value() {
    return this.i;
  }

  set(n) {
    this.i = n;
  }

  copy() {
    return new Index(this.i);
  }
}

const acceptLetter = (acceptChar, content, i) => {
  const isChar = content[i.value()] === acceptChar;
  if (isChar) {
    i.increment();
  }
  return isChar;
};

const acceptSequence = (str, content, i) => {
  const start = i.value();
  for (const l of str) {
    if (!acceptLetter(l, content, i)) {
      i.set(start);
      return false;
    }
  }
  return true;
};

const acceptCharType = (acceptChars, content, i) => {
  assert(i.value() < content.length);
  const char = content[i.value()];
  const isChar = acceptChars.indexOf(char) !== -1;
  if (isChar) {
    i.increment();
  }
  return isChar;
};

const acceptHex = partial(acceptCharType, hexalphanumeric);

export const isEthAddress = (content) => {
  const i = new Index();

  try {
    // const tagStart = i.value();
    console.log("got before sequence");
    console.log("content", content);
    assert(acceptSequence("0x", content, i));
    console.log("got after seq");
    // accept 40 hex chars
    for (let j = 0; j < 40; j++) {
      assert(acceptHex(content, i));
    }
    return true;
  } catch (e) {
    console.log("error validating addr?", e);
    return false;
  }
};
