import { useState, useEffect } from "react";
import { ContractFactory } from "ethers";

export const useDiamondFactory = (abi, bytecode, signer) => {
  const [diamondFactory, setDiamondFactory] = useState(undefined);

  useEffect(() => {
    const getNetwork = async () => {
      if (!!signer && abi !== null && bytecode !== null) {
        // let network = await provider.getNetwork();
        let df = new ContractFactory(abi, bytecode, signer)
        setDiamondFactory(df);
      }
    };
    getNetwork();
  }, [abi, bytecode, signer]);

  return diamondFactory;
};
