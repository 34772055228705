import React, { useState } from "react";
import { get } from "lodash";
import { useAddress } from "../hooks/useAddress";
import * as styles from "../App.module.scss";
import { isEthAddress } from "../helpers/validateEthAddress";
import { ethers } from "ethers";
import {
  contractAddressByNetwork,
  getAddressByNetwork,
} from "../helpers/contractAddresses";
import { FacetCutAction, getSelectors } from "../helpers/diamond";

// my diamond
// 0x37925daae2c81b7c13f4f06a8b924bfa2b32b539

const getAbi = (contractConfig, name) => {
  const abi = get(contractConfig, `deployedContracts.${name}.abi`, null);
  return abi;
};

export default function CutInFacets(props) {
  const {
    provider,
    signer,
    networkToShow,
    contractConfig,
    diamondDeployedBytecode,
  } = props;
  const [created, setCreated] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [diamondAddr, setDiamondAddr] = useState("");
  const [validAddr, setValidAddr] = useState(false);
  const [validDiamond, setValidDiamond] = useState(false);
  const [diamondCutInstance, setDiamondCutInstance] = useState(null);
  const signerAddress = useAddress(signer);

  const handleDiamondAddrChange = (event) => {
    const val = event.target.value;
    console.log("val", val);
    setDiamondAddr(val);

    // check if val is a valid address?
    const v = isEthAddress(val);
    setValidAddr(v);
    console.log("v", v);
    if (v) {
      const diamondCutAbi = getAbi(contractConfig, "IDiamondCut");
      console.log("diamondCutAbi", diamondCutAbi);
      const dci = new ethers.Contract(val, diamondCutAbi, signer);
      // ropsten
      // grab diamond address
      console.log("diamondInstance", dci);
      setDiamondCutInstance(dci);

      if (!!signerAddress) {
        provider.getCode(val).then((deployedBytecode) => {
          setValidDiamond(deployedBytecode === diamondDeployedBytecode);
        });
      }
    } else {
      setDiamondCutInstance(null);
      setValidDiamond(false);
    }
  };

  const cutInFacets = (event) => {
    event.preventDefault();
    const FacetNames = ["DiamondLoupeFacet", "OwnershipFacet"];

    const cut = [];
    for (const FacetName of FacetNames) {
      const facetAddress = getAddressByNetwork(networkToShow, FacetName);
      if (facetAddress === null) {
        throw Error("Invalid network");
      }
      const facetAbi = getAbi(contractConfig, FacetName);
      // console.log("facetAbi", facetAbi);
      const facet = new ethers.Contract(facetAddress, facetAbi, signer);

      cut.push({
        facetAddress: facet.address,
        action: FacetCutAction.Add,
        functionSelectors: getSelectors(facet),
      });
    }

    // grab diamondInit
    const diamondInitAddr = getAddressByNetwork(networkToShow, "DiamondInit");
    const diamondInit = new ethers.Contract(
      diamondInitAddr,
      getAbi(contractConfig, "DiamondInit"),
      signer
    );
    let functionCall = diamondInit.interface.encodeFunctionData("init");

    diamondCutInstance
      .diamondCut(cut, diamondInit.address, functionCall)
      .then(() => {
        console.log("Success!!!");
        setCreated(true);
        setErrorMsg(null);
      })
      .catch((error) => {
        console.log("error");
        // console.log(error.message);
        const kLibDiamondCutError =
          "LibDiamondCut: Can't add function that already exists";
        if (error.message.includes(kLibDiamondCutError)) {
          setErrorMsg(kLibDiamondCutError);
        }
        console.error(error);
      });

    // declare the diamond address, as a cut loupe
    // call into cut function of cut loupe
    // pass it

    // upgrade diamond with facets
    // console.log("");
    // console.log("Diamond Cut:", cut);
    // const diamondCut = await ethers.getContractAt(
    //   "IDiamondCut",
    //   diamond.address
    // );
    // let tx;
    // let receipt;
    // // call to init function
    // let functionCall = diamondInit.interface.encodeFunctionData("init");

    // tx = await diamondCut.diamondCut(cut, diamondInit.address, functionCall);
    // console.log("Diamond cut tx: ", tx.hash);

    // receipt = await tx.wait()
    // if (!receipt.status) {
    //   throw Error(`Diamond upgrade failed: ${tx.hash}`)
    // }
    // console.log('Completed diamond cut')
    // return diamond.address
  };

  const validContent = validAddr ? (
    <span key="validAddr" className={styles.addressValidLabel}>
      Valid ethereum address
    </span>
  ) : null;

  const baseUrl = get(contractAddressByNetwork, `${networkToShow}.baseUrl`, "");

  const validDiamondContent = validDiamond ? (
    <span key="validDiamond" className={styles.addressValidLabel}>
      <a href={`${baseUrl}${diamondAddr}`}>Valid Diamond</a>
    </span>
  ) : validAddr ? (
    <span key="notValidDiamond" className={styles.addressValidLabel}>
      Not a valid diamond or Wallet not connected.
    </span>
  ) : null;

  const errorMessage =
    errorMsg !== null ? (
      <div key="errormsg" className={styles.warning}>
        {errorMsg}
      </div>
    ) : null;

  const successMessage = created ? (
    <div key="successmsg" className={styles.successMessage}>
      Congratulations! Your transaction to deploy a diamond is pending. Your
      wallet should alert you transaction success in a couple minutes.
    </div>
  ) : null;

  return (
    <div className={styles.createDiamondSection}>
      <p>
        Once you have created your diamond, you still need to cut in its
        ownership and loupe facets. Enter the address of your new diamond below.
        You can only do this once.
      </p>
      <form onSubmit={cutInFacets}>
        <div className={styles.addrbox}>
          <label>Diamond address:</label>
          <input
            type="text"
            className={styles.addressInput}
            onChange={handleDiamondAddrChange}
          ></input>
          {validContent}
          {validDiamondContent}
        </div>
        <button
          type="submit"
          className={styles.cutInDiamond}
          disabled={!signerAddress || created}
        >
          Cut in Facets
        </button>
        {successMessage}
        {errorMessage}
      </form>
    </div>
  );
}
