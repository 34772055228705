import { useState, useEffect } from "react";
import { loadDiamondContract } from "../helpers/loadDiamondContract";

export const useContractConfig = (provider, ethNetwork) => {
  const [contractsConfig, setContractsConfig] = useState({});

  useEffect(() => {
    const loadFunc = async () => {
      const result = await loadDiamondContract(ethNetwork);
      setContractsConfig(result);
    };
    void loadFunc();
  }, [provider, ethNetwork]);
  return contractsConfig;
};
