import { get, has } from "lodash";
import { contractAddressByNetwork } from "./contractAddresses";

const diamondContract = import("../contracts/Diamond.json");
const diamondCutContract = import("../contracts/IDiamondCut.json");
const DiamondLoupeFacet = import("../contracts/DiamondLoupeFacet.json");
const OwnershipFacet = import("../contracts/OwnershipFacet.json");
const DiamondInit = import("../contracts/DiamondInit.json");

export const loadDiamondContract = async (EthNetworkName) => {
  const config = {};
  config.deployedContracts = {};
  config.deployedContracts.Diamond = (await diamondContract).default ?? {};

  console.log("EthNetworkName", EthNetworkName);
  const diamondAddress = get(
    contractAddressByNetwork,
    `${EthNetworkName}.Diamond`,
    ""
  );
  config.deployedContracts.Diamond.address = diamondAddress;

  config.deployedContracts.IDiamondCut =
    (await diamondCutContract).default ?? {};
  config.deployedContracts.OwnershipFacet =
    (await OwnershipFacet).default ?? {};
  config.deployedContracts.DiamondLoupeFacet =
    (await DiamondLoupeFacet).default ?? {};
  config.deployedContracts.DiamondInit = (await DiamondInit).default ?? {};

  // need to add address
  // config.deployedContracts.IDiamondCut.address = iDiamondCutAddress;

  return config;
};

// export const loadDiamondCutContract
