import React, { useState } from "react";
import { keys, map } from "lodash";
import * as styles from "../App.module.scss";
import * as cn from "classnames";
import {
  networkDisplayNames,
  networkUrls,
  polygonNetworkConfig,
} from "../helpers/supportedNetworks";

export async function changeNetwork(
  provider,
  networkNameDestination,
  updateNetwork
) {
  // just ask to switch ethereum chains if ropsten/mainnet
  //

  if (networkNameDestination === "homestead") {
    try {
      await provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
      updateNetwork();
    } catch (switchError) {
      console.error(switchError);
    }
  } else if (networkNameDestination === "ropsten") {
    // 3
    try {
      await provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x3" }],
      });
      updateNetwork();
    } catch (switchError) {
      console.error(switchError);
    }
  } else if (networkNameDestination === "matic") {
    try {
      await provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      });
      updateNetwork();
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log("switchError", switchError);
      if (switchError.code === 4902) {
        try {
          await provider.provider.request({
            method: "wallet_addEthereumChain",
            params: [polygonNetworkConfig],
          });
          updateNetwork();
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }
}

export default function NetworkSelector(props) {
  const {
    EthNetworkName,
    setEthNetworkName,
    network,
    updateNetwork,
    provider,
  } = props;
  const [opened, setOpened] = useState(false);
  // grab environment
  // grab list of environments

  let networkToShow = network !== undefined ? network.name : EthNetworkName;
  // if (networkToShow === "homestead") {
  //   networkToShow = "mainnet";
  // }

  const contents = map(keys(networkUrls), (k) => {
    if (k === networkToShow) {
      return null;
    }
    return (
      <div
        key={k}
        className={styles.networkUrl}
        onClick={() => {
          // setEthNetworkName(k);
          changeNetwork(provider, k, updateNetwork);
          // set should trigger change
        }}
      >
        {networkDisplayNames[k]}
      </div>
    );
  });

  // provider
  // this should also do the network switch modal

  return (
    <div className={styles.networkSelector} onClick={() => setOpened(!opened)}>
      Network:
      <span className={styles.currentNetwork}>
        {networkDisplayNames[networkToShow]}
      </span>
      <div
        className={cn(styles.networkSelectorDropdown, {
          [styles.showDropdown]: opened,
        })}
      >
        {contents}
      </div>
    </div>
  );
}
