import React, { useCallback, useState } from "react";
import { useAddress } from "../hooks/useAddress";
import { useNetwork } from "../hooks/useNetwork";
import * as styles from "../App.module.scss";
import { networkDisplayNames } from "../helpers/supportedNetworks";

export default function Web3Info(props) {
  const { provider, signer, updateSigner } = props;
  const [currentAccount, setCurrentAccount] = useState(null);
  const [network, _] = useNetwork(provider);
  const networkName = network ? network.name : "Loading";

  const networkId = network ? network.chainId : undefined;

  const signerAddress = useAddress(signer);

  const requestAccess = useCallback(() => {
    // For now, 'eth_accounts' will continue to always return an array
    function handleAccountsChanged(newAccounts) {
      if (newAccounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log("Please connect to MetaMask.");
      } else if (newAccounts[0] !== currentAccount) {
        setCurrentAccount(newAccounts[0]);
        updateSigner();
        // Do any other work!
      }
    }

    const requestAuth = async (web3Context) => {
      web3Context.provider
        .request({ method: "eth_requestAccounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
    };

    requestAuth(provider);
    // eslint-disable-next-line
  }, [provider, signerAddress, currentAccount, updateSigner]);

  const contents = !!signerAddress ? (
    <>
      <div>
        Network:{" "}
        {networkId
          ? `${networkId} – ${networkDisplayNames[networkName]}, "${networkName}"`
          : "No connection"}
      </div>
      <div>Your address: {signerAddress}</div>
    </>
  ) : (
    <div>
      <button onClick={requestAccess} className={styles.connectWallet}>
        Connect your Metamask wallet
      </button>
    </div>
  );

  return (
    <div className={props.className}>
      <h3 className={styles.no_top_margin}>Your wallet information</h3>
      {contents}
    </div>
  );
}
