import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { get, has } from "lodash";
import Web3Info from "./components/Web3Info";
import CreateDiamond from "./components/CreateDiamond";
// import Donations from "./components/Donations";
import { useContractConfig } from "./hooks/useContractConfig";
import { useNetwork } from "./hooks/useNetwork";
import { useSigner } from "./hooks/useSigner";
import { useContractLoader } from "eth-hooks";
import * as styles from "./App.module.scss";
import { useDiamondFactory } from "./hooks/useDiamondFactory";
import CutInFacets from "./components/CutInFacets";
import NetworkSelector from "./components/NetworkSelector";
import VerifiedContracts from "./components/VerifiedContracts";
import { contractAddressByNetwork } from "./helpers/contractAddresses";

const useProvider = (wndEthereum) => {
  const [provider, setProvider] = useState(null);

  const updateProvider = () => {
    let p = null;
    if (wndEthereum !== undefined) {
      p = new ethers.providers.Web3Provider(wndEthereum, "any");
      setProvider(p);
    }
    return p;
  };

  useEffect(() => {
    const p = updateProvider();
    if (p !== null) {
      p.on("network", () => {
        console.log("got network change");
        updateProvider();
      });
    }
  }, [wndEthereum]);

  return provider;
};

export function AppProvider(props) {
  const provider = useProvider(window.ethereum);
  return <App provider={provider}></App>;
}

export function App(props) {
  const { provider } = props;
  const [network, updateNetwork] = useNetwork(provider);

  const [signer, updateSigner] = useSigner(provider);
  const [EthNetworkName, setEthNetworkName] = useState("homestead");

  let networkToShow = network !== undefined ? network.name : EthNetworkName;

  const contractConfig = useContractConfig(provider, networkToShow);
  console.log("contractConfig", contractConfig);

  const [chainId, setChainId] = useState();
  useEffect(() => {
    if (network !== undefined) {
      setChainId(network.chainId);
    }
  }, [network]);

  const readContracts = useContractLoader(provider, contractConfig, chainId);
  const writeContracts = useContractLoader(signer, contractConfig, chainId);

  const diamondAbi = get(contractConfig, "deployedContracts.Diamond.abi", null);
  const diamondBytecode = get(
    contractConfig,
    "deployedContracts.Diamond.bytecode",
    null
  );
  const diamondDeployedBytecode = get(
    contractConfig,
    "deployedContracts.Diamond.deployedBytecode",
    ""
  );

  const diamondFactory = useDiamondFactory(diamondAbi, diamondBytecode, signer);
  // WARNING
  const warning = has(contractAddressByNetwork, networkToShow) ? null : (
    <div className={styles.warning}>
      Invalid network. Please select a supported network. If you want a network
      to be added, contact NorthwestNFTs.
    </div>
  );

  return (
    <div className="App">
      <div>
        <div className={styles.header}>
          <div className={styles.header_half}>
            <h1>Diamantaire.eth</h1>
            <p className={styles.header_description}>
              Deploy a Diamond with Diamantaire, by{" "}
              <a href="https://www.northwestnfts.com/">NorthwestNFTs</a>
            </p>
            <p className={styles.header_description}>
              <a href="https://github.com/mudgen/diamond-2-hardhat">
                Diamond-2 implementation reference
              </a>{" "}
              by <a href="https://github.com/mudgen">mudgen</a>. Learn more
              about Diamonds{" "}
              <a href="https://github.com/mudgen/Diamond">here</a>.
            </p>
            <VerifiedContracts networkToShow={networkToShow} />
          </div>
          <Web3Info
            provider={provider}
            signer={signer}
            updateSigner={updateSigner}
            className={styles.header_half}
          />
          <NetworkSelector
            provider={provider}
            EthNetworkName={EthNetworkName}
            setEthNetworkName={setEthNetworkName}
            network={network}
            updateNetwork={updateNetwork}
          />
        </div>
        {warning}
        <CreateDiamond
          provider={provider}
          signer={signer}
          writeContracts={writeContracts}
          diamondFactory={diamondFactory}
          networkToShow={networkToShow}
        />
        <CutInFacets
          provider={provider}
          signer={signer}
          writeContracts={writeContracts}
          diamondDeployedBytecode={diamondDeployedBytecode}
          contractConfig={contractConfig}
          networkToShow={networkToShow}
        />
      </div>
    </div>
  );
}
//
export default App;
