import { get, map } from "lodash";
import * as styles from "../App.module.scss";
import { contractAddressByNetwork } from "../helpers/contractAddresses";

const getUrl = (networkToShow, contract) => {
  const baseUrl = get(contractAddressByNetwork, `${networkToShow}.baseUrl`, "");
  const path = get(
    contractAddressByNetwork,
    `${networkToShow}.${contract}`,
    ""
  );
  return `${baseUrl}${path}`;
};

const contracts = [
  "Diamond",
  "DiamondCutFacet",
  "OwnershipFacet",
  "DiamondLoupeFacet",
  "DiamondInit",
];

export default function VerifiedContracts(props) {
  const { networkToShow } = props;
  // console.log("networkToShow", networkToShow);
  const links = map(contracts, (contract) => {
    const path = get(
      contractAddressByNetwork,
      `${networkToShow}.${contract}`,
      ""
    );

    return (
      <div key={contract}>
        <a href={getUrl(networkToShow, contract)}>{contract}</a>{" "}
        <span>{path}</span>
      </div>
    );
  });
  return (
    <div className={styles.header_description}>
      Contracts verified on Etherscan:
      {links}
    </div>
  );
}

/*

      <br />
      <a href={etherscanUrlsByNetwork[networkToShow]["Diamond"]}>Diamond</a>
      <br />
      <a href={etherscanUrlsByNetwork[networkToShow]["DiamondInit"]}>
        DiamondInit
      </a>
      <br />
      <a href={etherscanUrlsByNetwork[networkToShow]["DiamondCutFacet"]}>
        DiamondCutFacet
      </a>
      <br />
      <a href={etherscanUrlsByNetwork[networkToShow]["OwnershipFacet"]}>
        OwnershipFacet
      </a>
      <br />
      <a href={etherscanUrlsByNetwork[networkToShow]["DiamondLoupeFacet"]}>
        DiamondLoupeFacet
      </a>
      */
